.home-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.home-container01 {
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-size: cover;
  background-image: url("../playground_assets/wn_011-1500w.jpg");
}
.home-container02 {
  width: 100%;
  display: flex;
  opacity: 90%;
  align-items: center;
  flex-direction: column;
  background-color: var(--dl-color-gray-white);
}
.home-container03 {
  width: 100%;
  height: 80px;
  display: flex;
  max-width: var(--dl-size-size-maxi);
  align-items: center;
  padding-top: 0px;
  padding-left: var(--dl-space-space-twounits);
  padding-right: var(--dl-space-space-twounits);
  padding-bottom: 0px;
  justify-content: space-between;
}
.home-navlink {
  display: contents;
}
.home-image {
  width: 200px;
  object-fit: cover;
  text-decoration: none;
}
.home-container04 {
  display: flex;
  align-items: flex-start;
}
.home-navlink01 {
  color: var(--dl-color-primary-100);
  transition: 0.3s;
  text-decoration: none;
}
.home-navlink01:hover {
  color: var(--dl-color-primary-300);
}
.home-navlink02 {
  color: var(--dl-color-primary-100);
  transition: 0.3s;
  margin-left: var(--dl-space-space-twounits);
  text-decoration: none;
}
.home-navlink02:hover {
  color: var(--dl-color-primary-300);
}
.home-navlink03 {
  color: var(--dl-color-primary-100);
  transition: 0.3s;
  margin-left: var(--dl-space-space-twounits);
  text-decoration: none;
}
.home-navlink03:hover {
  color: var(--dl-color-primary-300);
}
.home-navlink04 {
  color: var(--dl-color-primary-100);
  transition: 0.3s;
  margin-left: var(--dl-space-space-twounits);
  text-decoration: none;
}
.home-navlink04:hover {
  color: var(--dl-color-primary-300);
}
.home-container05 {
  width: 100%;
  height: 750px;
  display: flex;
  padding: var(--dl-space-space-twounits);
  max-width: var(--dl-size-size-maxi);
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.home-container06 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.home-image01 {
  width: 250px;
  object-fit: cover;
  margin-bottom: var(--dl-space-space-threeunits);
}
.home-text01 {
  color: var(--dl-color-primary-5001);
  width: 650px;
  margin-bottom: var(--dl-space-space-threeunits);
}
.home-navlink05 {
  color: var(--dl-color-primary-5001);
  transition: 0.3s;
  border-color: var(--dl-color-gray-400);
  padding-left: 0px;
  border-radius: 0px;
  padding-right: 0px;
  padding-bottom: var(--dl-space-space-unit);
  text-decoration: none;
  background-color: transparent;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 1px;
}
.home-navlink05:hover {
  color: var(--dl-color-gray-white);
  border-color: var(--dl-color-primary-300);
}
.home-container07 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.home-container08 {
  display: flex;
  padding: var(--dl-space-space-twounits);
  max-width: var(--dl-size-size-maxi);
  align-items: flex-start;
}
.home-container09 {
  flex: 0 0 auto;
  width: 50%;
  display: flex;
  padding: var(--dl-space-space-oneandhalfunits);
  align-items: flex-start;
  flex-direction: column;
}
.home-text04 {
  color: var(--dl-color-primary-100);
  padding-top: 0px;
  border-color: var(--dl-color-primary-300);
  border-style: solid;
  border-width: 2px;
  padding-left: 0px;
  margin-bottom: var(--dl-space-space-twounits);
  padding-right: 0px;
  padding-bottom: var(--dl-space-space-unit);
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}
.home-text05 {
  text-align: justify;
}
.home-container10 {
  flex: 0 0 auto;
  width: 50%;
  display: flex;
  padding: var(--dl-space-space-oneandhalfunits);
  align-items: flex-start;
  flex-direction: column;
}
.home-text08 {
  color: var(--dl-color-primary-100);
  border-color: var(--dl-color-primary-300);
  border-width: 2px;
  margin-bottom: var(--dl-space-space-twounits);
  padding-bottom: var(--dl-space-space-unit);
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}
.home-text09 {
  text-align: justify;
}
.home-container11 {
  width: 100%;
  height: 694px;
  display: flex;
  align-items: center;
  background-size: cover;
  justify-content: center;
  background-image: url("../playground_assets/wn_02-1500w.jpg");
}
.home-container12 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-twounits);
  max-width: var(--dl-size-size-maxi);
  align-items: center;
  flex-direction: column;
}
.home-text12 {
  color: var(--dl-color-gray-white);
  align-self: center;
  margin-top: 0px;
  text-align: left;
  margin-bottom: var(--dl-space-space-fiveunits);
}
.home-container13 {
  display: flex;
  align-items: stretch;
  flex-direction: row;
}
.home-container14 {
  width: 362px;
  height: 500px;
  display: flex;
  max-width: 1000px;
  align-self: flex-start;
  align-items: flex-start;
  margin-bottom: 0px;
  flex-direction: column;
  justify-content: space-between;
}
.home-image02 {
  width: 70%;
  object-fit: cover;
}
.home-image03 {
  width: 50%;
  object-fit: cover;
}
.home-image04 {
  width: 60%;
  object-fit: cover;
}
.home-image05 {
  width: 50%;
  object-fit: cover;
}
.home-container15 {
  width: 362px;
  height: 500px;
  display: flex;
  max-width: 1000px;
  align-self: flex-start;
  align-items: center;
  margin-bottom: 0px;
  flex-direction: column;
  justify-content: space-between;
}
.home-image06 {
  width: 50%;
  object-fit: cover;
}
.home-image07 {
  width: 50%;
  object-fit: cover;
}
.home-image08 {
  width: 30%;
  object-fit: cover;
}
.home-image09 {
  width: 50%;
  height: 62px;
  object-fit: cover;
}
.home-container16 {
  width: 362px;
  height: 500px;
  display: flex;
  max-width: 1000px;
  align-self: flex-start;
  align-items: flex-end;
  margin-bottom: 0px;
  flex-direction: column;
  justify-content: space-between;
}
.home-image10 {
  width: 50%;
  object-fit: cover;
}
.home-image11 {
  width: 50%;
  object-fit: cover;
}
.home-image12 {
  width: 50%;
  object-fit: cover;
}
.home-image13 {
  width: 60%;
  object-fit: cover;
}
.home-container17 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  background-color: var(--dl-color-primary-100);
}
.home-container18 {
  flex: 0 0 auto;
  width: 100%;
  height: 569px;
  display: flex;
  padding: var(--dl-space-space-twounits);
  max-width: var(--dl-size-size-maxi);
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.home-text15 {
  color: var(--dl-color-gray-white);
  margin-bottom: var(--dl-space-space-fiveunits);
}
.home-container19 {
  width: 1100px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.home-navlink06 {
  display: contents;
}
.home-container20 {
  width: 250px;
  height: 300px;
  display: flex;
  transition: 0.3s;
  align-items: center;
  border-radius: 10%;
  justify-content: center;
  text-decoration: none;
  background-color: var(--dl-color-primary-300);
}
.home-container20:hover {
  background-color: var(--dl-color-gray-400);
}
.home-text19 {
  color: var(--dl-color-gray-white);
  text-align: center;
}
.home-navlink07 {
  display: contents;
}
.home-container21 {
  width: 250px;
  height: 300px;
  display: flex;
  transition: 0.3s;
  align-items: center;
  border-radius: 10%;
  justify-content: center;
  text-decoration: none;
  background-color: var(--dl-color-primary-300);
}
.home-container21:hover {
  background-color: var(--dl-color-gray-400);
}
.home-text21 {
  color: var(--dl-color-gray-white);
  text-align: center;
}
.home-navlink08 {
  display: contents;
}
.home-container22 {
  width: 250px;
  height: 300px;
  display: flex;
  transition: 0.3s;
  align-items: center;
  border-radius: 10%;
  justify-content: center;
  text-decoration: none;
  background-color: var(--dl-color-primary-300);
}
.home-container22:hover {
  background-color: var(--dl-color-gray-400);
}
.home-text23 {
  color: var(--dl-color-gray-white);
  text-align: center;
}
.home-navlink09 {
  display: contents;
}
.home-container23 {
  width: 250px;
  height: 300px;
  display: flex;
  transition: 0.3s;
  align-items: center;
  border-radius: 10%;
  justify-content: center;
  text-decoration: none;
  background-color: var(--dl-color-primary-300);
}
.home-container23:hover {
  background-color: var(--dl-color-gray-400);
}
.home-text29 {
  color: var(--dl-color-gray-white);
  text-align: center;
}
.home-footer {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-container24 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-twounits);
  max-width: var(--dl-size-size-maxi);
  align-self: center;
  align-items: flex-start;
  flex-direction: row;
}
.home-container25 {
  width: 50%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-text33 {
  color: var(--dl-color-primary-100);
  line-height: 1.15;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.home-navlink10 {
  color: var(--dl-color-primary-100);
  transition: 0.3s;
  border-color: var(--dl-color-primary-300);
  padding-left: 0px;
  border-radius: 0px;
  padding-right: 0px;
  padding-bottom: var(--dl-space-space-unit);
  text-decoration: none;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 2px;
}
.home-navlink10:hover {
  color: var(--dl-color-primary-100);
  border-color: var(--dl-color-gray-400);
}
.home-container26 {
  width: 50%;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
}
.home-navlink11 {
  color: var(--dl-color-primary-100);
  line-height: 1.15;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
  text-decoration: none;
}
.home-container27 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-container28 {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.home-container29 {
  display: none;
  align-items: flex-start;
  flex-direction: row;
}
.home-icon {
  fill: var(--dl-color-primary-300);
  width: 30px;
  height: 30px;
  margin-right: var(--dl-space-space-unit);
}
.home-icon2 {
  fill: var(--dl-color-primary-300);
  width: 30px;
  height: 30px;
  margin-right: var(--dl-space-space-unit);
}
.home-icon4 {
  fill: var(--dl-color-primary-300);
  width: 30px;
  height: 30px;
  margin-right: var(--dl-space-space-unit);
}
.home-icon6 {
  fill: var(--dl-color-primary-300);
  width: 30px;
  height: 30px;
}
.home-container30 {
  display: flex;
  align-items: flex-end;
  margin-left: 0px;
  flex-direction: column;
}
.home-container31 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  background-color: var(--dl-color-primary-300);
}
.home-container32 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-twounits);
  max-width: var(--dl-size-size-maxi);
  align-self: center;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
}
.home-container33 {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
}
.home-container34 {
  display: flex;
  align-items: flex-start;
  margin-right: var(--dl-space-space-threeunits);
  flex-direction: column;
}
.home-navlink12 {
  color: var(--dl-color-gray-white);
  transition: 0.3s;
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.home-navlink12:hover {
  color: var(--dl-color-primary-900);
}
.home-navlink13 {
  color: var(--dl-color-gray-white);
  transition: 0.3s;
  text-decoration: none;
}
.home-navlink13:hover {
  color: var(--dl-color-primary-900);
}
.home-container35 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-navlink14 {
  color: var(--dl-color-gray-white);
  transition: 0.3s;
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.home-navlink14:hover {
  color: var(--dl-color-primary-900);
}
.home-navlink15 {
  color: var(--dl-color-gray-white);
  transition: 0.3s;
  text-decoration: none;
}
.home-navlink15:hover {
  color: var(--dl-color-primary-900);
}
.home-container36 {
  display: flex;
  align-items: flex-end;
  flex-direction: column;
}
.home-text36 {
  color: var(--dl-color-gray-white);
  margin-bottom: var(--dl-space-space-unit);
}
.home-navlink16 {
  color: var(--dl-color-gray-white);
  transition: 0.3s;
  text-decoration: none;
}
.home-navlink16:hover {
  color: var(--dl-color-primary-900);
}
