.contact-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.contact-container01 {
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-size: cover;
  background-image: url("../playground_assets/wn_10-1500w.jpg");
}
.contact-container02 {
  width: 100%;
  display: flex;
  opacity: 90%;
  align-items: center;
  flex-direction: column;
  background-color: var(--dl-color-gray-white);
}
.contact-container03 {
  width: 100%;
  height: 80px;
  display: flex;
  max-width: var(--dl-size-size-maxi);
  align-items: center;
  padding-top: 0px;
  padding-left: var(--dl-space-space-twounits);
  padding-right: var(--dl-space-space-twounits);
  padding-bottom: 0px;
  justify-content: space-between;
}
.contact-navlink {
  display: contents;
}
.contact-image {
  width: 200px;
  object-fit: cover;
  text-decoration: none;
}
.contact-container04 {
  display: flex;
  align-items: flex-start;
}
.contact-navlink01 {
  color: var(--dl-color-primary-100);
  transition: 0.3s;
  text-decoration: none;
}
.contact-navlink01:hover {
  color: var(--dl-color-primary-300);
}
.contact-navlink02 {
  color: var(--dl-color-primary-100);
  transition: 0.3s;
  margin-left: var(--dl-space-space-twounits);
  text-decoration: none;
}
.contact-navlink02:hover {
  color: var(--dl-color-primary-300);
}
.contact-navlink03 {
  color: var(--dl-color-primary-100);
  transition: 0.3s;
  margin-left: var(--dl-space-space-twounits);
  text-decoration: none;
}
.contact-navlink03:hover {
  color: var(--dl-color-primary-300);
}
.contact-navlink04 {
  color: var(--dl-color-primary-100);
  transition: 0.3s;
  margin-left: var(--dl-space-space-twounits);
  text-decoration: none;
}
.contact-navlink04:hover {
  color: var(--dl-color-primary-300);
}
.contact-container05 {
  width: 100%;
  height: 750px;
  display: flex;
  padding: var(--dl-space-space-twounits);
  max-width: var(--dl-size-size-maxi);
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.contact-container06 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.contact-text01 {
  color: var(--dl-color-primary-5001);
  width: 650px;
  font-size: 80px;
  font-style: normal;
  font-family: Urbanist;
  font-weight: 600;
  line-height: 1.15;
  margin-bottom: 0px;
  text-transform: none;
  text-decoration: none;
}
.contact-container07 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.contact-container08 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxi);
  margin-top: var(--dl-space-space-threeunits);
  align-items: flex-start;
  padding-left: var(--dl-space-space-twounits);
  margin-bottom: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-twounits);
  flex-direction: column;
}
.contact-text03 {
  color: var(--dl-color-primary-300);
  font-size: 60px;
  font-style: normal;
  font-family: Urbanist;
  font-weight: 600;
  line-height: 1.15;
  margin-bottom: var(--dl-space-space-twounits);
  text-transform: none;
  text-decoration: none;
}
.contact-text06 {
  line-height: 1.15;
}
.contact-form {
  width: 100%;
  margin-top: var(--dl-space-space-threeunits);
  margin-bottom: var(--dl-space-space-threeunits);
}
.contact-text07 {
  color: var(--dl-color-primary-100);
}
.contact-textinput {
  width: 100%;
  padding: var(--dl-space-space-unit);
  margin-top: var(--dl-space-space-twounits);
  border-color: var(--dl-color-primary-300);
  border-radius: 0px;
  margin-bottom: var(--dl-space-space-threeunits);
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 2px;
}
.contact-text08 {
  color: var(--dl-color-primary-100);
}
.contact-textinput1 {
  width: 100%;
  padding: var(--dl-space-space-unit);
  margin-top: var(--dl-space-space-twounits);
  border-color: var(--dl-color-primary-300);
  border-radius: 0px;
  margin-bottom: var(--dl-space-space-threeunits);
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 2px;
}
.contact-text09 {
  color: var(--dl-color-primary-100);
}
.contact-textarea {
  width: 100%;
  height: var(--dl-size-size-medium);
  padding: var(--dl-space-space-unit);
  margin-top: var(--dl-space-space-threeunits);
  border-color: var(--dl-color-primary-300);
  border-width: 2px;
  border-radius: 0px;
  margin-bottom: var(--dl-space-space-threeunits);
}
.contact-button {
  color: var(--dl-color-gray-white);
  transition: 0.3s;
  border-color: var(--dl-color-primary-300);
  border-width: 0px;
  border-radius: var(--dl-radius-radius-radius8);
  background-color: var(--dl-color-primary-300);
}
.contact-button:hover {
  border-color: var(--dl-color-gray-400);
  border-width: 0px;
  background-color: var(--dl-color-gray-400);
}
.contact-footer {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.contact-container09 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-twounits);
  max-width: var(--dl-size-size-maxi);
  align-self: center;
  align-items: flex-start;
  flex-direction: row;
}
.contact-container10 {
  width: 50%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.contact-text12 {
  color: var(--dl-color-primary-100);
  line-height: 1.15;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.contact-navlink05 {
  color: var(--dl-color-primary-100);
  transition: 0.3s;
  border-color: var(--dl-color-primary-300);
  padding-left: 0px;
  border-radius: 0px;
  padding-right: 0px;
  padding-bottom: var(--dl-space-space-unit);
  text-decoration: none;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 2px;
}
.contact-navlink05:hover {
  color: var(--dl-color-primary-100);
  border-color: var(--dl-color-gray-400);
}
.contact-container11 {
  width: 50%;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
}
.contact-navlink06 {
  color: var(--dl-color-primary-100);
  line-height: 1.15;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
  text-decoration: none;
}
.contact-container12 {
  display: flex;
  align-items: flex-end;
  flex-direction: column;
}
.contact-container13 {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.contact-container14 {
  display: none;
  align-items: flex-start;
  flex-direction: row;
}
.contact-icon {
  fill: var(--dl-color-primary-300);
  width: 30px;
  height: 30px;
  margin-right: var(--dl-space-space-unit);
}
.contact-icon2 {
  fill: var(--dl-color-primary-300);
  width: 30px;
  height: 30px;
  margin-right: var(--dl-space-space-unit);
}
.contact-icon4 {
  fill: var(--dl-color-primary-300);
  width: 30px;
  height: 30px;
  margin-right: var(--dl-space-space-unit);
}
.contact-icon6 {
  fill: var(--dl-color-primary-300);
  width: 30px;
  height: 30px;
}
.contact-container15 {
  display: flex;
  align-items: flex-end;
  margin-left: 0px;
  flex-direction: column;
}
.contact-container16 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  background-color: var(--dl-color-primary-300);
}
.contact-container17 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-twounits);
  max-width: var(--dl-size-size-maxi);
  align-self: center;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
}
.contact-container18 {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
}
.contact-container19 {
  display: flex;
  align-items: flex-start;
  margin-right: var(--dl-space-space-threeunits);
  flex-direction: column;
}
.contact-navlink07 {
  color: var(--dl-color-gray-white);
  transition: 0.3s;
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.contact-navlink07:hover {
  color: var(--dl-color-primary-900);
}
.contact-navlink08 {
  color: var(--dl-color-gray-white);
  transition: 0.3s;
  text-decoration: none;
}
.contact-navlink08:hover {
  color: var(--dl-color-primary-900);
}
.contact-container20 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.contact-navlink09 {
  color: var(--dl-color-gray-white);
  transition: 0.3s;
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.contact-navlink09:hover {
  color: var(--dl-color-primary-900);
}
.contact-navlink10 {
  color: var(--dl-color-gray-white);
  transition: 0.3s;
  text-decoration: none;
}
.contact-navlink10:hover {
  color: var(--dl-color-primary-900);
}
.contact-container21 {
  display: flex;
  align-items: flex-end;
  flex-direction: column;
}
.contact-text15 {
  color: var(--dl-color-gray-white);
  margin-bottom: var(--dl-space-space-unit);
}
.contact-navlink11 {
  color: var(--dl-color-gray-white);
  transition: 0.3s;
  text-decoration: none;
}
.contact-navlink11:hover {
  color: var(--dl-color-primary-900);
}
