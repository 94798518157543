.embedded-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.embedded-container01 {
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-size: cover;
  background-image: url("../playground_assets/wn_08-1500w.jpg");
}
.embedded-container02 {
  width: 100%;
  display: flex;
  opacity: 90%;
  align-items: center;
  flex-direction: column;
  background-color: var(--dl-color-gray-white);
}
.embedded-container03 {
  width: 100%;
  height: 80px;
  display: flex;
  max-width: var(--dl-size-size-maxi);
  align-items: center;
  padding-top: 0px;
  padding-left: var(--dl-space-space-twounits);
  padding-right: var(--dl-space-space-twounits);
  padding-bottom: 0px;
  justify-content: space-between;
}
.embedded-navlink {
  display: contents;
}
.embedded-image {
  width: 200px;
  object-fit: cover;
  text-decoration: none;
}
.embedded-container04 {
  display: flex;
  align-items: flex-start;
}
.embedded-navlink01 {
  color: var(--dl-color-primary-100);
  transition: 0.3s;
  text-decoration: none;
}
.embedded-navlink01:hover {
  color: var(--dl-color-primary-300);
}
.embedded-navlink02 {
  color: var(--dl-color-primary-100);
  transition: 0.3s;
  margin-left: var(--dl-space-space-twounits);
  text-decoration: none;
}
.embedded-navlink02:hover {
  color: var(--dl-color-primary-300);
}
.embedded-navlink03 {
  color: var(--dl-color-primary-100);
  transition: 0.3s;
  margin-left: var(--dl-space-space-twounits);
  text-decoration: none;
}
.embedded-navlink03:hover {
  color: var(--dl-color-primary-300);
}
.embedded-navlink04 {
  color: var(--dl-color-primary-100);
  transition: 0.3s;
  margin-left: var(--dl-space-space-twounits);
  text-decoration: none;
}
.embedded-navlink04:hover {
  color: var(--dl-color-primary-300);
}
.embedded-container05 {
  width: 100%;
  height: 750px;
  display: flex;
  padding: var(--dl-space-space-twounits);
  max-width: var(--dl-size-size-maxi);
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.embedded-container06 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.embedded-text01 {
  color: var(--dl-color-primary-5001);
  width: 650px;
  font-size: 80px;
  font-style: normal;
  font-family: Urbanist;
  font-weight: 700;
  line-height: 1.15;
  margin-bottom: var(--dl-space-space-twounits);
  text-transform: none;
  text-decoration: none;
}
.embedded-text03 {
  color: var(--dl-color-gray-white);
  width: 50%;
  height: 100%;
  padding: 0px;
  font-size: 20px;
  font-family: Manrope;
  font-weight: 500;
  text-transform: none;
  text-decoration: none;
}
.embedded-container07 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  margin-top: var(--dl-space-space-threeunits);
  align-items: center;
  margin-bottom: var(--dl-space-space-threeunits);
  flex-direction: column;
}
.embedded-container08 {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.embedded-container09 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxi);
  align-items: center;
  padding-top: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-twounits);
  padding-right: var(--dl-space-space-twounits);
  padding-bottom: var(--dl-space-space-fourunits);
}
.embedded-container10 {
  width: 50%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.embedded-text06 {
  margin-bottom: var(--dl-space-space-twounits);
}
.embedded-container11 {
  flex: 0 0 auto;
  width: 50%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.embedded-image1 {
  height: 200px;
  object-fit: cover;
}
.embedded-container12 {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: var(--dl-color-primary-900);
}
.embedded-container13 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxi);
  align-items: center;
  padding-top: var(--dl-space-space-fourunits);
  padding-left: var(--dl-space-space-twounits);
  padding-right: var(--dl-space-space-twounits);
  padding-bottom: var(--dl-space-space-fourunits);
}
.embedded-container14 {
  flex: 0 0 auto;
  width: 50%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.embedded-image2 {
  height: 250px;
  object-fit: cover;
}
.embedded-container15 {
  width: 50%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.embedded-text13 {
  margin-bottom: var(--dl-space-space-twounits);
}
.embedded-container16 {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.embedded-container17 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxi);
  align-items: center;
  padding-top: var(--dl-space-space-fourunits);
  padding-left: var(--dl-space-space-twounits);
  padding-right: var(--dl-space-space-twounits);
  padding-bottom: var(--dl-space-space-twounits);
}
.embedded-container18 {
  width: 50%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.embedded-text22 {
  margin-bottom: var(--dl-space-space-twounits);
}
.embedded-container19 {
  flex: 0 0 auto;
  width: 50%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.embedded-image3 {
  height: 200px;
  object-fit: cover;
}
.embedded-container20 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  background-color: var(--dl-color-gray-50);
}
.embedded-container21 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxi);
  flex-direction: row;
  justify-content: space-between;
}
.embedded-container22 {
  width: 33%;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-threeunits);
  padding-left: var(--dl-space-space-twounits);
  border-radius: var(--dl-radius-radius-radius8);
  padding-right: var(--dl-space-space-twounits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-threeunits);
  justify-content: flex-start;
}
.embedded-container23 {
  height: 130px;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-twounits);
  flex-direction: column;
  justify-content: flex-start;
}
.embedded-text33 {
  color: var(--dl-color-gray-white);
  text-align: center;
}
.embedded-text38 {
  color: var(--dl-color-gray-white);
  text-align: center;
}
.embedded-container24 {
  width: 34%;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-threeunits);
  padding-left: var(--dl-space-space-twounits);
  padding-right: var(--dl-space-space-twounits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-threeunits);
  justify-content: flex-start;
  background-color: var(--dl-color-gray-100);
}
.embedded-container25 {
  height: 130px;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-twounits);
  flex-direction: column;
  justify-content: center;
}
.embedded-text41 {
  color: var(--dl-color-primary-100);
  text-align: center;
}
.embedded-text44 {
  color: var(--dl-color-primary-100);
  text-align: center;
}
.embedded-container26 {
  width: 33%;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-threeunits);
  padding-left: var(--dl-space-space-twounits);
  border-radius: var(--dl-radius-radius-radius8);
  padding-right: var(--dl-space-space-twounits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-threeunits);
  justify-content: flex-start;
}
.embedded-container27 {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
}
.embedded-container28 {
  height: 130px;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-twounits);
  flex-direction: column;
  justify-content: center;
}
.embedded-text47 {
  color: var(--dl-color-gray-white);
  text-align: center;
}
.embedded-text50 {
  color: var(--dl-color-gray-white);
  text-align: center;
}
.embedded-footer {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.embedded-container29 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-twounits);
  max-width: var(--dl-size-size-maxi);
  align-self: center;
  align-items: flex-start;
  flex-direction: row;
}
.embedded-container30 {
  width: 50%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.embedded-text53 {
  color: var(--dl-color-primary-100);
  line-height: 1.15;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.embedded-navlink05 {
  color: var(--dl-color-primary-100);
  transition: 0.3s;
  border-color: var(--dl-color-primary-300);
  padding-left: 0px;
  border-radius: 0px;
  padding-right: 0px;
  padding-bottom: var(--dl-space-space-unit);
  text-decoration: none;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 2px;
}
.embedded-navlink05:hover {
  color: var(--dl-color-primary-100);
  border-color: var(--dl-color-gray-400);
}
.embedded-container31 {
  width: 50%;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
}
.embedded-navlink06 {
  color: var(--dl-color-primary-100);
  line-height: 1.15;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
  text-decoration: none;
}
.embedded-container32 {
  display: flex;
  align-items: flex-end;
  flex-direction: column;
}
.embedded-container33 {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.embedded-container34 {
  display: none;
  align-items: flex-start;
  flex-direction: row;
}
.embedded-icon {
  fill: var(--dl-color-primary-300);
  width: 30px;
  height: 30px;
  margin-right: var(--dl-space-space-unit);
}
.embedded-icon2 {
  fill: var(--dl-color-primary-300);
  width: 30px;
  height: 30px;
  margin-right: var(--dl-space-space-unit);
}
.embedded-icon4 {
  fill: var(--dl-color-primary-300);
  width: 30px;
  height: 30px;
  margin-right: var(--dl-space-space-unit);
}
.embedded-icon6 {
  fill: var(--dl-color-primary-300);
  width: 30px;
  height: 30px;
}
.embedded-container35 {
  display: flex;
  align-items: flex-end;
  margin-left: 0px;
  flex-direction: column;
}
.embedded-container36 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  background-color: var(--dl-color-primary-300);
}
.embedded-container37 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-twounits);
  max-width: var(--dl-size-size-maxi);
  align-self: center;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
}
.embedded-container38 {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
}
.embedded-container39 {
  display: flex;
  align-items: flex-start;
  margin-right: var(--dl-space-space-threeunits);
  flex-direction: column;
}
.embedded-navlink07 {
  color: var(--dl-color-gray-white);
  transition: 0.3s;
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.embedded-navlink07:hover {
  color: var(--dl-color-primary-900);
}
.embedded-navlink08 {
  color: var(--dl-color-gray-white);
  transition: 0.3s;
  text-decoration: none;
}
.embedded-navlink08:hover {
  color: var(--dl-color-primary-900);
}
.embedded-container40 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.embedded-navlink09 {
  color: var(--dl-color-gray-white);
  transition: 0.3s;
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.embedded-navlink09:hover {
  color: var(--dl-color-primary-900);
}
.embedded-navlink10 {
  color: var(--dl-color-gray-white);
  transition: 0.3s;
  text-decoration: none;
}
.embedded-navlink10:hover {
  color: var(--dl-color-primary-900);
}
.embedded-container41 {
  display: flex;
  align-items: flex-end;
  flex-direction: column;
}
.embedded-text56 {
  color: var(--dl-color-gray-white);
  margin-bottom: var(--dl-space-space-unit);
}
.embedded-navlink11 {
  color: var(--dl-color-gray-white);
  transition: 0.3s;
  text-decoration: none;
}
.embedded-navlink11:hover {
  color: var(--dl-color-primary-900);
}
