.biometrics-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.biometrics-container01 {
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-size: cover;
  background-image: url("../playground_assets/wn_09-1500w.jpg");
}
.biometrics-container02 {
  width: 100%;
  display: flex;
  opacity: 90%;
  align-items: center;
  flex-direction: column;
  background-color: var(--dl-color-gray-white);
}
.biometrics-container03 {
  width: 100%;
  height: 80px;
  display: flex;
  max-width: var(--dl-size-size-maxi);
  align-items: center;
  padding-top: 0px;
  padding-left: var(--dl-space-space-twounits);
  padding-right: var(--dl-space-space-twounits);
  padding-bottom: 0px;
  justify-content: space-between;
}
.biometrics-navlink {
  display: contents;
}
.biometrics-image {
  width: 200px;
  object-fit: cover;
  text-decoration: none;
}
.biometrics-container04 {
  display: flex;
  align-items: flex-start;
}
.biometrics-navlink01 {
  color: var(--dl-color-primary-100);
  transition: 0.3s;
  text-decoration: none;
}
.biometrics-navlink01:hover {
  color: var(--dl-color-primary-300);
}
.biometrics-navlink02 {
  color: var(--dl-color-primary-100);
  transition: 0.3s;
  margin-left: var(--dl-space-space-twounits);
  text-decoration: none;
}
.biometrics-navlink02:hover {
  color: var(--dl-color-primary-300);
}
.biometrics-navlink03 {
  color: var(--dl-color-primary-100);
  transition: 0.3s;
  margin-left: var(--dl-space-space-twounits);
  text-decoration: none;
}
.biometrics-navlink03:hover {
  color: var(--dl-color-primary-300);
}
.biometrics-navlink04 {
  color: var(--dl-color-primary-100);
  margin-left: var(--dl-space-space-twounits);
  text-decoration: none;
}
.biometrics-container05 {
  width: 100%;
  height: 750px;
  display: flex;
  padding: var(--dl-space-space-twounits);
  max-width: var(--dl-size-size-maxi);
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.biometrics-container06 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.biometrics-text01 {
  color: var(--dl-color-primary-5001);
  width: 650px;
  font-size: 80px;
  font-style: normal;
  font-family: Urbanist;
  font-weight: 700;
  line-height: 1.15;
  margin-bottom: var(--dl-space-space-twounits);
  text-transform: none;
  text-decoration: none;
}
.biometrics-text03 {
  color: var(--dl-color-gray-white);
  width: 50%;
  height: 100%;
  padding: 0px;
  font-size: 20px;
  font-family: Manrope;
  font-weight: 500;
  text-transform: none;
  text-decoration: none;
}
.biometrics-container07 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  margin-top: var(--dl-space-space-threeunits);
  align-items: flex-start;
  margin-bottom: var(--dl-space-space-unit);
  justify-content: center;
}
.biometrics-container08 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxi);
  align-items: flex-start;
  padding-top: 0px;
  padding-left: var(--dl-space-space-twounits);
  padding-right: var(--dl-space-space-twounits);
  padding-bottom: 0px;
  justify-content: space-between;
}
.biometrics-text06 {
  color: var(--dl-color-primary-100);
  width: 50%;
  padding: var(--dl-space-space-twounits);
  line-height: 1.15;
}
.biometrics-text16 {
  color: var(--dl-color-primary-100);
  width: 50%;
  padding: var(--dl-space-space-twounits);
  line-height: 1.15;
}
.biometrics-container09 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  background-color: var(--dl-color-gray-200);
}
.biometrics-container10 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxi);
  align-items: flex-start;
  padding-top: var(--dl-space-space-threeunits);
  padding-left: var(--dl-space-space-twounits);
  padding-right: var(--dl-space-space-twounits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-threeunits);
  justify-content: space-between;
}
.biometrics-text23 {
  color: var(--dl-color-gray-white);
  font-size: 60px;
  font-style: normal;
  font-family: Urbanist;
  font-weight: 600;
  line-height: 1.15;
  margin-bottom: var(--dl-space-space-threeunits);
  text-transform: none;
  text-decoration: none;
}
.biometrics-container11 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.biometrics-container12 {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.biometrics-image01 {
  height: 100px;
  object-fit: cover;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.biometrics-text26 {
  color: var(--dl-color-gray-white);
  font-size: 25px;
  font-style: normal;
  text-align: center;
  font-family: Urbanist;
  font-weight: 600;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
.biometrics-container13 {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.biometrics-image02 {
  height: 100px;
  object-fit: cover;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.biometrics-text30 {
  color: var(--dl-color-gray-white);
  font-size: 25px;
  font-style: normal;
  text-align: center;
  font-family: Urbanist;
  font-weight: 600;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
.biometrics-container14 {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.biometrics-image03 {
  height: 100px;
  object-fit: cover;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.biometrics-text35 {
  color: var(--dl-color-gray-white);
  font-size: 25px;
  font-style: normal;
  text-align: center;
  font-family: Urbanist;
  font-weight: 600;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
.biometrics-container15 {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.biometrics-image04 {
  height: 110px;
  object-fit: cover;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.biometrics-text40 {
  color: var(--dl-color-gray-white);
  font-size: 25px;
  font-style: normal;
  text-align: center;
  font-family: Urbanist;
  font-weight: 600;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
.biometrics-container16 {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.biometrics-image05 {
  height: 100px;
  object-fit: cover;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.biometrics-text48 {
  color: var(--dl-color-gray-white);
  font-size: 25px;
  font-style: normal;
  text-align: center;
  font-family: Urbanist;
  font-weight: 600;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
.biometrics-container17 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  background-color: var(--dl-color-primary-900);
}
.biometrics-container18 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxi);
  align-items: flex-start;
  padding-top: var(--dl-space-space-threeunits);
  padding-left: var(--dl-space-space-twounits);
  padding-right: var(--dl-space-space-twounits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-threeunits);
  justify-content: space-between;
}
.biometrics-text52 {
  color: var(--dl-color-primary-100);
  font-size: 60px;
  font-style: normal;
  font-family: Urbanist;
  font-weight: 600;
  line-height: 1.15;
  margin-bottom: var(--dl-space-space-threeunits);
  text-transform: none;
  text-decoration: none;
}
.biometrics-container19 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.biometrics-container20 {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.biometrics-image06 {
  height: 100px;
  object-fit: cover;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.biometrics-text54 {
  color: var(--dl-color-primary-100);
  font-size: 25px;
  font-style: normal;
  text-align: center;
  font-family: Urbanist;
  font-weight: 600;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
.biometrics-container21 {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.biometrics-image07 {
  height: 100px;
  object-fit: cover;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.biometrics-text61 {
  color: var(--dl-color-primary-100);
  font-size: 25px;
  font-style: normal;
  text-align: center;
  font-family: Urbanist;
  font-weight: 600;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
.biometrics-container22 {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.biometrics-image08 {
  height: 100px;
  object-fit: cover;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.biometrics-text66 {
  color: var(--dl-color-primary-100);
  font-size: 25px;
  font-style: normal;
  text-align: center;
  font-family: Urbanist;
  font-weight: 600;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
.biometrics-container23 {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.biometrics-image09 {
  height: 100px;
  object-fit: cover;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.biometrics-text71 {
  color: var(--dl-color-primary-100);
  font-size: 25px;
  font-style: normal;
  text-align: center;
  font-family: Urbanist;
  font-weight: 600;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
.biometrics-container24 {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.biometrics-image10 {
  height: 100px;
  object-fit: cover;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.biometrics-text76 {
  color: var(--dl-color-primary-100);
  font-size: 25px;
  font-style: normal;
  text-align: center;
  font-family: Urbanist;
  font-weight: 600;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
.biometrics-footer {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.biometrics-container25 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-twounits);
  max-width: var(--dl-size-size-maxi);
  align-self: center;
  align-items: flex-start;
  flex-direction: row;
}
.biometrics-container26 {
  width: 50%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.biometrics-text82 {
  color: var(--dl-color-primary-100);
  line-height: 1.15;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.biometrics-navlink05 {
  color: var(--dl-color-primary-100);
  transition: 0.3s;
  border-color: var(--dl-color-primary-300);
  padding-left: 0px;
  border-radius: 0px;
  padding-right: 0px;
  padding-bottom: var(--dl-space-space-unit);
  text-decoration: none;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 2px;
}
.biometrics-navlink05:hover {
  color: var(--dl-color-primary-100);
  border-color: var(--dl-color-gray-400);
}
.biometrics-container27 {
  width: 50%;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
}
.biometrics-navlink06 {
  color: var(--dl-color-primary-100);
  line-height: 1.15;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
  text-decoration: none;
}
.biometrics-container28 {
  display: flex;
  align-items: flex-end;
  flex-direction: column;
}
.biometrics-container29 {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.biometrics-container30 {
  display: none;
  align-items: flex-start;
  flex-direction: row;
}
.biometrics-icon {
  fill: var(--dl-color-primary-300);
  width: 30px;
  height: 30px;
  margin-right: var(--dl-space-space-unit);
}
.biometrics-icon2 {
  fill: var(--dl-color-primary-300);
  width: 30px;
  height: 30px;
  margin-right: var(--dl-space-space-unit);
}
.biometrics-icon4 {
  fill: var(--dl-color-primary-300);
  width: 30px;
  height: 30px;
  margin-right: var(--dl-space-space-unit);
}
.biometrics-icon6 {
  fill: var(--dl-color-primary-300);
  width: 30px;
  height: 30px;
}
.biometrics-container31 {
  display: flex;
  align-items: flex-end;
  margin-left: 0px;
  flex-direction: column;
}
.biometrics-container32 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  background-color: var(--dl-color-primary-300);
}
.biometrics-container33 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-twounits);
  max-width: var(--dl-size-size-maxi);
  align-self: center;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
}
.biometrics-container34 {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
}
.biometrics-container35 {
  display: flex;
  align-items: flex-start;
  margin-right: var(--dl-space-space-threeunits);
  flex-direction: column;
}
.biometrics-navlink07 {
  color: var(--dl-color-gray-white);
  transition: 0.3s;
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.biometrics-navlink07:hover {
  color: var(--dl-color-primary-900);
}
.biometrics-navlink08 {
  color: var(--dl-color-gray-white);
  transition: 0.3s;
  text-decoration: none;
}
.biometrics-navlink08:hover {
  color: var(--dl-color-primary-900);
}
.biometrics-container36 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.biometrics-navlink09 {
  color: var(--dl-color-gray-white);
  transition: 0.3s;
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.biometrics-navlink09:hover {
  color: var(--dl-color-primary-900);
}
.biometrics-navlink10 {
  color: var(--dl-color-gray-white);
  transition: 0.3s;
  text-decoration: none;
}
.biometrics-navlink10:hover {
  color: var(--dl-color-primary-900);
}
.biometrics-container37 {
  display: flex;
  align-items: flex-end;
  flex-direction: column;
}
.biometrics-text85 {
  color: var(--dl-color-gray-white);
  margin-bottom: var(--dl-space-space-unit);
}
.biometrics-navlink11 {
  color: var(--dl-color-gray-white);
  transition: 0.3s;
  text-decoration: none;
}
.biometrics-navlink11:hover {
  color: var(--dl-color-primary-900);
}
