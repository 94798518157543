:root {
  --dl-color-gray-0: #5a69bfff;
  --dl-color-gray-50: #eb80a8ff;
  --dl-color-gray-100: #f1cfd8ff;
  --dl-color-gray-200: #21bfc0ff;
  --dl-color-gray-400: #daa406ff;
  --dl-color-gray-500: #595959;
  --dl-color-gray-700: #999999;
  --dl-color-gray-900: #D9D9D9;
  --dl-size-size-maxi: 1200px;
  --dl-size-size-large: 144px;
  --dl-size-size-small: 48px;
  --dl-color-danger-300: #A22020;
  --dl-color-danger-500: #BF2626;
  --dl-color-danger-700: #E14747;
  --dl-color-gray-black: #000000;
  --dl-color-gray-white: #FFFFFF;
  --dl-size-size-medium: 96px;
  --dl-size-size-xlarge: 192px;
  --dl-size-size-xsmall: 16px;
  --dl-space-space-unit: 16px;
  --dl-color-primary-100: #021059ff;
  --dl-color-primary-300: #3d46f2ff;
  --dl-color-primary-500: #9aaad9ff;
  --dl-color-primary-700: #85DCFF;
  --dl-color-primary-900: #dfe4f2ff;
  --dl-color-success-300: #199033;
  --dl-color-success-500: #32A94C;
  --dl-color-success-700: #4CC366;
  --dl-size-size-xxlarge: 288px;
  --dl-color-primary-5001: #f2f2f2ff;
  --dl-size-size-maxwidth: 1400px;
  --dl-radius-radius-round: 50%;
  --dl-space-space-halfunit: 8px;
  --dl-space-space-sixunits: 96px;
  --dl-space-space-twounits: 32px;
  --dl-radius-radius-radius2: 2px;
  --dl-radius-radius-radius4: 4px;
  --dl-radius-radius-radius8: 8px;
  --dl-space-space-fiveunits: 80px;
  --dl-space-space-fourunits: 64px;
  --dl-space-space-threeunits: 48px;
  --dl-space-space-oneandhalfunits: 24px;
}
.button {
  color: var(--dl-color-gray-black);
  display: inline-block;
  padding: 0.5rem 1rem;
  border-color: var(--dl-color-gray-black);
  border-width: 1px;
  border-radius: 4px;
  background-color: var(--dl-color-gray-white);
}
.input {
  color: var(--dl-color-gray-black);
  cursor: auto;
  padding: 0.5rem 1rem;
  border-color: var(--dl-color-gray-black);
  border-width: 1px;
  border-radius: 4px;
  background-color: var(--dl-color-gray-white);
}
.textarea {
  color: var(--dl-color-gray-black);
  cursor: auto;
  padding: 0.5rem;
  border-color: var(--dl-color-gray-black);
  border-width: 1px;
  border-radius: 4px;
  background-color: var(--dl-color-gray-white);
}
.list {
  width: 100%;
  margin: 1em 0px 1em 0px;
  display: block;
  padding: 0px 0px 0px 1.5rem;
  list-style-type: none;
  list-style-position: outside;
}
.list-item {
  display: list-item;
}
.teleport-show {
  display: flex !important;
}
.sub-Heading {
  font-size: 40px;
  font-family: Urbanist;
  font-weight: 600;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
.boton {
  font-size: 25px;
  font-family: Urbanist;
  font-weight: 500;
  text-transform: none;
  text-decoration: none;
}
.heading {
  font-size: 45px;
  font-family: Urbanist;
  font-weight: 500;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
.footer {
  font-size: 30px;
  font-family: Urbanist;
  font-weight: 500;
  text-transform: none;
  text-decoration: none;
}
.menu {
  font-size: 20px;
  font-family: Manrope;
  font-weight: 500;
  text-transform: none;
  text-decoration: none;
}
.content {
  font-size: 19px;
  font-family: Urbanist;
  font-weight: 400;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
.unidades {
  font-size: 35px;
  font-family: Urbanist;
  font-weight: 600;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
.heading1 {
  font-size: 60px;
  font-family: Urbanist;
  font-weight: 500;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
