.about-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.about-container01 {
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-size: cover;
  background-image: url("../playground_assets/wn_03-1500w.jpg");
}
.about-container02 {
  width: 100%;
  display: flex;
  opacity: 90%;
  align-items: center;
  flex-direction: column;
  background-color: var(--dl-color-gray-white);
}
.about-container03 {
  width: 100%;
  height: 80px;
  display: flex;
  max-width: var(--dl-size-size-maxi);
  align-items: center;
  padding-top: 0px;
  padding-left: var(--dl-space-space-twounits);
  padding-right: var(--dl-space-space-twounits);
  padding-bottom: 0px;
  justify-content: space-between;
}
.about-navlink {
  display: contents;
}
.about-image {
  width: 200px;
  object-fit: cover;
  text-decoration: none;
}
.about-container04 {
  display: flex;
  align-items: flex-start;
}
.about-navlink01 {
  color: var(--dl-color-primary-100);
  transition: 0.3s;
  text-decoration: none;
}
.about-navlink01:hover {
  color: var(--dl-color-primary-300);
}
.about-navlink02 {
  color: var(--dl-color-primary-100);
  transition: 0.3s;
  margin-left: var(--dl-space-space-twounits);
  text-decoration: none;
}
.about-navlink02:hover {
  color: var(--dl-color-primary-300);
}
.about-navlink03 {
  color: var(--dl-color-primary-100);
  transition: 0.3s;
  margin-left: var(--dl-space-space-twounits);
  text-decoration: none;
}
.about-navlink03:hover {
  color: var(--dl-color-primary-300);
}
.about-navlink04 {
  color: var(--dl-color-primary-100);
  transition: 0.3s;
  margin-left: var(--dl-space-space-twounits);
  text-decoration: none;
}
.about-navlink04:hover {
  color: var(--dl-color-primary-300);
}
.about-container05 {
  width: 100%;
  height: 750px;
  display: flex;
  padding: var(--dl-space-space-twounits);
  max-width: var(--dl-size-size-maxi);
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.about-container06 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.about-text01 {
  color: var(--dl-color-primary-5001);
  width: 650px;
  font-size: 80px;
  font-style: normal;
  font-family: Urbanist;
  font-weight: 600;
  line-height: 1.15;
  margin-bottom: 0px;
  text-transform: none;
  text-decoration: none;
}
.about-container07 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  margin-top: var(--dl-space-space-fourunits);
  align-items: flex-start;
  margin-bottom: var(--dl-space-space-fourunits);
  justify-content: center;
  background-color: var(--dl-color-gray-white);
}
.about-container08 {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxi);
  align-items: center;
  padding-top: 0px;
  padding-left: var(--dl-space-space-twounits);
  padding-right: var(--dl-space-space-twounits);
  flex-direction: column;
  padding-bottom: 0px;
  justify-content: center;
}
.about-container09 {
  width: 1100px;
  display: flex;
  max-width: var(--dl-size-size-maxi);
  align-items: flex-start;
  justify-content: space-between;
}
.about-container10 {
  display: flex;
  align-items: center;
  margin-right: 0px;
  flex-direction: column;
}
.about-text03 {
  color: var(--dl-color-primary-300);
  font-size: 100px;
  font-style: normal;
  font-family: Urbanist;
  font-weight: 800;
  line-height: 1.15;
  margin-right: var(--dl-space-space-unit);
  margin-bottom: 0px;
  text-transform: none;
  text-decoration: none;
}
.about-text04 {
  font-size: 25px;
  text-align: center;
  font-family: Urbanist;
  font-weight: 500;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
.about-container11 {
  width: 385px;
  display: flex;
  align-items: center;
  margin-right: 0px;
  flex-direction: column;
}
.about-text08 {
  color: var(--dl-color-primary-300);
  font-size: 100px;
  font-style: normal;
  font-family: Urbanist;
  font-weight: 800;
  line-height: 1.15;
  margin-right: var(--dl-space-space-unit);
  margin-bottom: 0px;
  text-transform: none;
  text-decoration: none;
}
.about-text09 {
  color: var(--dl-color-primary-300);
  font-size: 35px;
  font-style: normal;
  text-align: center;
  font-family: Urbanist;
  font-weight: 700;
  text-transform: none;
  text-decoration: none;
}
.about-text10 {
  font-size: 25px;
  text-align: center;
  font-family: Urbanist;
  font-weight: 500;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
.about-container12 {
  display: flex;
  align-items: center;
  margin-right: 0px;
  flex-direction: column;
}
.about-text17 {
  color: var(--dl-color-primary-300);
  font-size: 100px;
  font-style: normal;
  font-family: Urbanist;
  font-weight: 800;
  line-height: 1.15;
  margin-right: var(--dl-space-space-unit);
  margin-bottom: 0px;
  text-transform: none;
  text-decoration: none;
}
.about-text18 {
  font-size: 25px;
  text-align: center;
  font-family: Urbanist;
  font-weight: 500;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
.about-container13 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
}
.about-container14 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-threeunits);
  padding-left: var(--dl-space-space-twounits);
  padding-right: var(--dl-space-space-twounits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-threeunits);
  justify-content: center;
  background-color: var(--dl-color-primary-100);
}
.about-container15 {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.about-container16 {
  width: 50%;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-fourunits);
  padding-right: var(--dl-space-space-twounits);
  justify-content: flex-end;
}
.about-text26 {
  color: var(--dl-color-gray-white);
  font-size: 40px;
  text-align: right;
  font-family: Urbanist;
  font-weight: 600;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
.about-container17 {
  width: 50%;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-fourunits);
  border-color: var(--dl-color-gray-white);
  border-width: 1px;
  padding-left: var(--dl-space-space-twounits);
  flex-direction: column;
  border-top-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
}
.about-text31 {
  color: var(--dl-color-gray-400);
  font-size: 40px;
  font-family: Urbanist;
  font-weight: 600;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
.about-text32 {
  color: var(--dl-color-gray-white);
  font-size: 40px;
  font-style: normal;
  font-family: Urbanist;
  font-weight: 300;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
.about-container18 {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.about-container19 {
  width: 50%;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-fourunits);
  padding-right: var(--dl-space-space-twounits);
  justify-content: flex-end;
}
.about-text38 {
  color: var(--dl-color-gray-400);
  font-size: 100px;
  text-align: right;
  font-family: Urbanist;
  font-weight: 600;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
.about-container20 {
  width: 50%;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-sixunits);
  border-color: var(--dl-color-gray-white);
  border-width: 1px;
  padding-left: var(--dl-space-space-twounits);
  flex-direction: column;
  padding-bottom: 0px;
  border-top-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
}
.about-text41 {
  color: var(--dl-color-gray-white);
}
.about-text44 {
  color: var(--dl-color-gray-white);
  font-size: 40px;
  font-style: normal;
  font-family: Urbanist;
  font-weight: 300;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
.about-text45 {
  font-family: Urbanist;
  text-transform: none;
  text-decoration: none;
}
.about-text47 {
  font-family: Urbanist;
  text-transform: none;
  text-decoration: none;
}
.about-container21 {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.about-container22 {
  width: 50%;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-fourunits);
  padding-right: var(--dl-space-space-twounits);
  justify-content: flex-end;
}
.about-text48 {
  color: var(--dl-color-gray-white);
  font-size: 100px;
  text-align: right;
  font-family: Urbanist;
  font-weight: 600;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
.about-container23 {
  width: 50%;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-sixunits);
  border-color: var(--dl-color-gray-white);
  border-width: 1px;
  padding-left: var(--dl-space-space-twounits);
  flex-direction: column;
  border-top-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
}
.about-text50 {
  color: var(--dl-color-gray-400);
}
.about-text54 {
  color: var(--dl-color-gray-white);
  font-size: 40px;
  font-style: normal;
  font-family: Urbanist;
  font-weight: 300;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
.about-container24 {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.about-container25 {
  width: 50%;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-fourunits);
  padding-right: var(--dl-space-space-twounits);
  justify-content: flex-end;
}
.about-text57 {
  color: var(--dl-color-gray-400);
  font-size: 100px;
  text-align: right;
  font-family: Urbanist;
  font-weight: 600;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
.about-container26 {
  width: 50%;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-sixunits);
  border-color: var(--dl-color-gray-white);
  border-width: 1px;
  padding-left: var(--dl-space-space-twounits);
  flex-direction: column;
  border-top-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
}
.about-text59 {
  color: var(--dl-color-gray-white);
}
.about-text65 {
  color: var(--dl-color-gray-white);
  font-size: 40px;
  font-style: normal;
  font-family: Urbanist;
  font-weight: 300;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
.about-container27 {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.about-container28 {
  width: 50%;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-fourunits);
  padding-right: var(--dl-space-space-twounits);
  justify-content: flex-end;
}
.about-text70 {
  color: var(--dl-color-gray-white);
  font-size: 100px;
  text-align: right;
  font-family: Urbanist;
  font-weight: 600;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
.about-container29 {
  width: 50%;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-sixunits);
  border-color: var(--dl-color-gray-white);
  border-width: 1px;
  padding-left: var(--dl-space-space-twounits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fourunits);
  border-top-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
}
.about-text72 {
  color: var(--dl-color-gray-400);
}
.about-text75 {
  color: var(--dl-color-gray-white);
  font-size: 40px;
  font-style: normal;
  font-family: Urbanist;
  font-weight: 300;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
.about-footer {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.about-container30 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-twounits);
  max-width: var(--dl-size-size-maxi);
  align-self: center;
  align-items: flex-start;
  flex-direction: row;
}
.about-container31 {
  width: 50%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.about-text80 {
  color: var(--dl-color-primary-100);
  line-height: 1.15;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.about-navlink05 {
  color: var(--dl-color-primary-100);
  transition: 0.3s;
  border-color: var(--dl-color-primary-300);
  padding-left: 0px;
  border-radius: 0px;
  padding-right: 0px;
  padding-bottom: var(--dl-space-space-unit);
  text-decoration: none;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 2px;
}
.about-navlink05:hover {
  color: var(--dl-color-primary-100);
  border-color: var(--dl-color-gray-400);
}
.about-container32 {
  width: 50%;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
}
.about-navlink06 {
  color: var(--dl-color-primary-100);
  line-height: 1.15;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
  text-decoration: none;
}
.about-container33 {
  display: flex;
  align-items: flex-end;
  flex-direction: column;
}
.about-container34 {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.about-container35 {
  display: none;
  align-items: flex-start;
  flex-direction: row;
}
.about-icon {
  fill: var(--dl-color-primary-300);
  width: 30px;
  height: 30px;
  margin-right: var(--dl-space-space-unit);
}
.about-icon2 {
  fill: var(--dl-color-primary-300);
  width: 30px;
  height: 30px;
  margin-right: var(--dl-space-space-unit);
}
.about-icon4 {
  fill: var(--dl-color-primary-300);
  width: 30px;
  height: 30px;
  margin-right: var(--dl-space-space-unit);
}
.about-icon6 {
  fill: var(--dl-color-primary-300);
  width: 30px;
  height: 30px;
}
.about-container36 {
  display: flex;
  align-items: flex-end;
  margin-left: 0px;
  flex-direction: column;
}
.about-container37 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  background-color: var(--dl-color-primary-300);
}
.about-container38 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-twounits);
  max-width: var(--dl-size-size-maxi);
  align-self: center;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
}
.about-container39 {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
}
.about-container40 {
  display: flex;
  align-items: flex-start;
  margin-right: var(--dl-space-space-threeunits);
  flex-direction: column;
}
.about-navlink07 {
  color: var(--dl-color-gray-white);
  transition: 0.3s;
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.about-navlink07:hover {
  color: var(--dl-color-primary-900);
}
.about-navlink08 {
  color: var(--dl-color-gray-white);
  transition: 0.3s;
  text-decoration: none;
}
.about-navlink08:hover {
  color: var(--dl-color-primary-900);
}
.about-container41 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.about-navlink09 {
  color: var(--dl-color-gray-white);
  transition: 0.3s;
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.about-navlink09:hover {
  color: var(--dl-color-primary-900);
}
.about-navlink10 {
  color: var(--dl-color-gray-white);
  transition: 0.3s;
  text-decoration: none;
}
.about-navlink10:hover {
  color: var(--dl-color-primary-900);
}
.about-container42 {
  display: flex;
  align-items: flex-end;
  flex-direction: column;
}
.about-text83 {
  color: var(--dl-color-gray-white);
  margin-bottom: var(--dl-space-space-unit);
}
.about-navlink11 {
  color: var(--dl-color-gray-white);
  transition: 0.3s;
  text-decoration: none;
}
.about-navlink11:hover {
  color: var(--dl-color-primary-900);
}
