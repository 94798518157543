.privacy-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.privacy-container01 {
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.privacy-container02 {
  width: 100%;
  display: flex;
  opacity: 90%;
  align-items: center;
  flex-direction: column;
  background-color: var(--dl-color-gray-white);
}
.privacy-container03 {
  width: 100%;
  height: 80px;
  display: flex;
  max-width: var(--dl-size-size-maxi);
  align-items: center;
  padding-top: 0px;
  padding-left: var(--dl-space-space-twounits);
  padding-right: var(--dl-space-space-twounits);
  padding-bottom: 0px;
  justify-content: space-between;
}
.privacy-navlink {
  display: contents;
}
.privacy-image {
  width: 200px;
  object-fit: cover;
  text-decoration: none;
}
.privacy-container04 {
  display: flex;
  align-items: flex-start;
}
.privacy-navlink01 {
  color: var(--dl-color-primary-100);
  transition: 0.3s;
  text-decoration: none;
}
.privacy-navlink01:hover {
  color: var(--dl-color-primary-300);
}
.privacy-navlink02 {
  color: var(--dl-color-primary-100);
  transition: 0.3s;
  margin-left: var(--dl-space-space-twounits);
  text-decoration: none;
}
.privacy-navlink02:hover {
  color: var(--dl-color-primary-300);
}
.privacy-navlink03 {
  color: var(--dl-color-primary-100);
  transition: 0.3s;
  margin-left: var(--dl-space-space-twounits);
  text-decoration: none;
}
.privacy-navlink03:hover {
  color: var(--dl-color-primary-300);
}
.privacy-navlink04 {
  color: var(--dl-color-primary-100);
  transition: 0.3s;
  margin-left: var(--dl-space-space-twounits);
  text-decoration: none;
}
.privacy-navlink04:hover {
  color: var(--dl-color-primary-300);
}
.privacy-container05 {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-primary-300);
}
.privacy-container06 {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-twounits);
  max-width: var(--dl-size-size-maxi);
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.privacy-text01 {
  color: var(--dl-color-primary-5001);
  width: 650px;
  margin-bottom: 0px;
}
.privacy-container07 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.privacy-container08 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxi);
  align-items: flex-start;
  padding-top: var(--dl-space-space-threeunits);
  padding-left: var(--dl-space-space-twounits);
  padding-right: var(--dl-space-space-twounits);
  padding-bottom: var(--dl-space-space-threeunits);
}
.privacy-text03 {
  color: var(--dl-color-primary-100);
  text-align: justify;
}
.privacy-text04 {
  color: var(--dl-color-primary-300);
}
.privacy-text07 {
  color: var(--dl-color-primary-300);
}
.privacy-text17 {
  color: var(--dl-color-primary-300);
}
.privacy-text21 {
  color: var(--dl-color-primary-300);
}
.privacy-text25 {
  color: var(--dl-color-primary-300);
}
.privacy-text29 {
  color: var(--dl-color-primary-300);
}
.privacy-footer {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.privacy-container09 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-twounits);
  max-width: var(--dl-size-size-maxi);
  align-self: center;
  align-items: flex-start;
  flex-direction: row;
}
.privacy-container10 {
  width: 50%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.privacy-text33 {
  color: var(--dl-color-primary-100);
  line-height: 1.15;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.privacy-navlink05 {
  color: var(--dl-color-primary-100);
  transition: 0.3s;
  border-color: var(--dl-color-primary-300);
  padding-left: 0px;
  border-radius: 0px;
  padding-right: 0px;
  padding-bottom: var(--dl-space-space-unit);
  text-decoration: none;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 2px;
}
.privacy-navlink05:hover {
  color: var(--dl-color-primary-100);
  border-color: var(--dl-color-gray-400);
}
.privacy-container11 {
  width: 50%;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
}
.privacy-navlink06 {
  color: var(--dl-color-primary-100);
  line-height: 1.15;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
  text-decoration: none;
}
.privacy-container12 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.privacy-container13 {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.privacy-container14 {
  display: none;
  align-items: flex-start;
  flex-direction: row;
}
.privacy-icon {
  fill: var(--dl-color-primary-300);
  width: 30px;
  height: 30px;
  margin-right: var(--dl-space-space-unit);
}
.privacy-icon2 {
  fill: var(--dl-color-primary-300);
  width: 30px;
  height: 30px;
  margin-right: var(--dl-space-space-unit);
}
.privacy-icon4 {
  fill: var(--dl-color-primary-300);
  width: 30px;
  height: 30px;
  margin-right: var(--dl-space-space-unit);
}
.privacy-icon6 {
  fill: var(--dl-color-primary-300);
  width: 30px;
  height: 30px;
}
.privacy-container15 {
  display: flex;
  align-items: flex-end;
  margin-left: 0px;
  flex-direction: column;
}
.privacy-container16 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  background-color: var(--dl-color-primary-300);
}
.privacy-container17 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-twounits);
  max-width: var(--dl-size-size-maxi);
  align-self: center;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
}
.privacy-container18 {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
}
.privacy-container19 {
  display: flex;
  align-items: flex-start;
  margin-right: var(--dl-space-space-threeunits);
  flex-direction: column;
}
.privacy-navlink07 {
  color: var(--dl-color-gray-white);
  transition: 0.3s;
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.privacy-navlink07:hover {
  color: var(--dl-color-primary-900);
}
.privacy-navlink08 {
  color: var(--dl-color-gray-white);
  transition: 0.3s;
  text-decoration: none;
}
.privacy-navlink08:hover {
  color: var(--dl-color-primary-900);
}
.privacy-container20 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.privacy-navlink09 {
  color: var(--dl-color-gray-white);
  transition: 0.3s;
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.privacy-navlink09:hover {
  color: var(--dl-color-primary-900);
}
.privacy-navlink10 {
  color: var(--dl-color-gray-white);
  transition: 0.3s;
  text-decoration: none;
}
.privacy-navlink10:hover {
  color: var(--dl-color-primary-900);
}
.privacy-container21 {
  display: flex;
  align-items: flex-end;
  flex-direction: column;
}
.privacy-text36 {
  color: var(--dl-color-gray-white);
  margin-bottom: var(--dl-space-space-unit);
}
.privacy-navlink11 {
  color: var(--dl-color-gray-white);
  transition: 0.3s;
  text-decoration: none;
}
.privacy-navlink11:hover {
  color: var(--dl-color-primary-900);
}
