.a-i-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.a-i-container01 {
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-size: cover;
  background-image: url("../playground_assets/wn_05-1500w.jpg");
}
.a-i-container02 {
  width: 100%;
  display: flex;
  opacity: 90%;
  align-items: center;
  flex-direction: column;
  background-color: var(--dl-color-gray-white);
}
.a-i-container03 {
  width: 100%;
  height: 80px;
  display: flex;
  max-width: var(--dl-size-size-maxi);
  align-items: center;
  padding-top: 0px;
  padding-left: var(--dl-space-space-twounits);
  padding-right: var(--dl-space-space-twounits);
  padding-bottom: 0px;
  justify-content: space-between;
}
.a-i-navlink {
  display: contents;
}
.a-i-image {
  width: 200px;
  object-fit: cover;
  text-decoration: none;
}
.a-i-container04 {
  display: flex;
  align-items: flex-start;
}
.a-i-navlink1 {
  color: var(--dl-color-primary-100);
  transition: 0.3s;
  text-decoration: none;
}
.a-i-navlink1:hover {
  color: var(--dl-color-primary-300);
}
.a-i-navlink2 {
  color: var(--dl-color-primary-100);
  transition: 0.3s;
  margin-left: var(--dl-space-space-twounits);
  text-decoration: none;
}
.a-i-navlink2:hover {
  color: var(--dl-color-primary-300);
}
.a-i-navlink3 {
  color: var(--dl-color-primary-100);
  transition: 0.3s;
  margin-left: var(--dl-space-space-twounits);
  text-decoration: none;
}
.a-i-navlink3:hover {
  color: var(--dl-color-primary-300);
}
.a-i-text01 {
  color: var(--dl-color-primary-100);
  margin-left: var(--dl-space-space-twounits);
}
.a-i-container05 {
  width: 100%;
  height: 750px;
  display: flex;
  padding: var(--dl-space-space-twounits);
  max-width: var(--dl-size-size-maxi);
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.a-i-container06 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.a-i-text02 {
  color: var(--dl-color-primary-5001);
  width: 650px;
  font-size: 80px;
  font-style: normal;
  font-family: Urbanist;
  font-weight: 700;
  line-height: 1.15;
  margin-bottom: var(--dl-space-space-twounits);
  text-transform: none;
  text-decoration: none;
}
.a-i-text04 {
  color: var(--dl-color-gray-white);
  width: 50%;
  height: 100%;
  padding: 0px;
  font-size: 20px;
  font-family: Manrope;
  font-weight: 500;
  text-transform: none;
  text-decoration: none;
}
.a-i-container07 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  margin-top: var(--dl-space-space-twounits);
  justify-content: center;
}
.a-i-container08 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxi);
  align-items: flex-start;
  padding-right: var(--dl-space-space-twounits);
  justify-content: space-between;
}
.a-i-container09 {
  flex: 0 0 auto;
  width: 33%;
  display: flex;
  padding: var(--dl-space-space-twounits);
  align-items: center;
  flex-direction: column;
}
.a-i-container10 {
  flex: 0 0 auto;
  width: 100%;
  height: 150px;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-unit);
  flex-direction: column;
  justify-content: center;
}
.a-i-image01 {
  height: 120px;
  object-fit: cover;
}
.a-i-container11 {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.a-i-text07 {
  color: var(--dl-color-primary-100);
  width: 100%;
  text-align: center;
  margin-bottom: var(--dl-space-space-twounits);
}
.a-i-text08 {
  text-align: center;
}
.a-i-container12 {
  flex: 0 0 auto;
  width: 33%;
  height: 100%;
  display: flex;
  padding: var(--dl-space-space-twounits);
  align-items: center;
  flex-direction: column;
}
.a-i-container13 {
  flex: 0 0 auto;
  width: 100%;
  height: 150px;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-unit);
  flex-direction: column;
  justify-content: center;
}
.a-i-image02 {
  height: 120px;
  object-fit: cover;
}
.a-i-container14 {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.a-i-text15 {
  color: var(--dl-color-primary-100);
  width: 100%;
  text-align: center;
  margin-bottom: var(--dl-space-space-twounits);
}
.a-i-text16 {
  text-align: center;
}
.a-i-container15 {
  flex: 0 0 auto;
  width: 33%;
  height: 100%;
  display: flex;
  padding: var(--dl-space-space-twounits);
  align-items: center;
  flex-direction: column;
}
.a-i-container16 {
  flex: 0 0 auto;
  width: 100%;
  height: 150px;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-unit);
  flex-direction: column;
  justify-content: center;
}
.a-i-image03 {
  height: 130px;
  object-fit: cover;
}
.a-i-container17 {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.a-i-text22 {
  color: var(--dl-color-primary-100);
  width: 100%;
  text-align: center;
  margin-bottom: var(--dl-space-space-twounits);
}
.a-i-text25 {
  text-align: center;
}
.a-i-container18 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  margin-top: var(--dl-space-space-twounits);
  margin-bottom: var(--dl-space-space-twounits);
  justify-content: center;
  background-color: var(--dl-color-primary-900);
}
.a-i-container19 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxi);
  align-items: flex-start;
  justify-content: space-between;
}
.a-i-container20 {
  flex: 0 0 auto;
  width: 33%;
  height: 100%;
  display: flex;
  padding: var(--dl-space-space-twounits);
  align-items: center;
  flex-direction: column;
}
.a-i-container21 {
  flex: 0 0 auto;
  width: 100%;
  height: 150px;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-unit);
  flex-direction: column;
  justify-content: center;
}
.a-i-image04 {
  height: 140px;
  object-fit: cover;
}
.a-i-container22 {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.a-i-text28 {
  color: var(--dl-color-primary-100);
  width: 100%;
  text-align: center;
  margin-bottom: var(--dl-space-space-twounits);
}
.a-i-text31 {
  text-align: center;
}
.a-i-container23 {
  flex: 0 0 auto;
  width: 34%;
  height: 100%;
  display: flex;
  padding: var(--dl-space-space-twounits);
  align-items: center;
  flex-direction: column;
}
.a-i-container24 {
  flex: 0 0 auto;
  width: 100%;
  height: 150px;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-unit);
  flex-direction: column;
  justify-content: center;
}
.a-i-image05 {
  height: 120px;
  object-fit: cover;
}
.a-i-container25 {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.a-i-text37 {
  color: var(--dl-color-primary-100);
  width: 100%;
  text-align: center;
  margin-bottom: var(--dl-space-space-twounits);
}
.a-i-text38 {
  text-align: center;
}
.a-i-container26 {
  flex: 0 0 auto;
  width: 33%;
  height: 100%;
  display: flex;
  padding: var(--dl-space-space-twounits);
  align-items: center;
  flex-direction: column;
}
.a-i-container27 {
  flex: 0 0 auto;
  width: 100%;
  height: 150px;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-unit);
  flex-direction: column;
  justify-content: center;
}
.a-i-image06 {
  height: 120px;
  object-fit: cover;
}
.a-i-container28 {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.a-i-text40 {
  color: var(--dl-color-primary-100);
  width: 100%;
  text-align: center;
  margin-bottom: var(--dl-space-space-twounits);
}
.a-i-text41 {
  text-align: center;
}
.a-i-container29 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  margin-bottom: var(--dl-space-space-twounits);
  justify-content: center;
  background-color: var(--dl-color-gray-white);
}
.a-i-container30 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxi);
  align-items: flex-start;
  justify-content: space-between;
}
.a-i-container31 {
  flex: 0 0 auto;
  width: 33%;
  display: flex;
  padding: var(--dl-space-space-twounits);
  align-items: center;
  flex-direction: column;
}
.a-i-container32 {
  flex: 0 0 auto;
  width: 100%;
  height: 150px;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-unit);
  flex-direction: column;
  justify-content: center;
}
.a-i-image07 {
  height: 135px;
  object-fit: cover;
}
.a-i-container33 {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.a-i-text43 {
  color: var(--dl-color-primary-100);
  width: 100%;
  text-align: center;
  margin-bottom: var(--dl-space-space-twounits);
}
.a-i-text45 {
  text-align: center;
}
.a-i-container34 {
  flex: 0 0 auto;
  width: 33%;
  height: 100%;
  display: flex;
  padding: var(--dl-space-space-twounits);
  align-items: center;
  flex-direction: column;
}
.a-i-container35 {
  flex: 0 0 auto;
  width: 100%;
  height: 150px;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-unit);
  flex-direction: column;
  justify-content: center;
}
.a-i-image08 {
  height: 120px;
  object-fit: cover;
}
.a-i-container36 {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.a-i-text48 {
  color: var(--dl-color-primary-100);
  width: 100%;
  text-align: center;
  margin-bottom: var(--dl-space-space-twounits);
}
.a-i-text51 {
  text-align: center;
}
.a-i-container37 {
  flex: 0 0 auto;
  width: 33%;
  height: 100%;
  display: flex;
  padding: var(--dl-space-space-twounits);
  align-items: center;
  flex-direction: column;
}
.a-i-container38 {
  flex: 0 0 auto;
  width: 100%;
  height: 150px;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-unit);
  flex-direction: column;
  justify-content: center;
}
.a-i-image09 {
  height: 130px;
  object-fit: cover;
}
.a-i-container39 {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.a-i-text54 {
  color: var(--dl-color-primary-100);
  width: 100%;
  text-align: center;
  margin-bottom: var(--dl-space-space-twounits);
}
.a-i-text55 {
  text-align: center;
}
.a-i-footer {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.a-i-container40 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-twounits);
  max-width: var(--dl-size-size-maxi);
  align-self: center;
  align-items: flex-start;
  flex-direction: row;
}
.a-i-container41 {
  width: 50%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.a-i-text57 {
  color: var(--dl-color-primary-100);
  line-height: 1.15;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.a-i-button {
  color: var(--dl-color-primary-100);
  transition: 0.3s;
  border-color: var(--dl-color-primary-300);
  padding-left: 0px;
  border-radius: 0px;
  padding-right: 0px;
  padding-bottom: var(--dl-space-space-unit);
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 2px;
}
.a-i-button:hover {
  color: var(--dl-color-primary-100);
  border-color: var(--dl-color-gray-400);
}
.a-i-container42 {
  width: 50%;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
}
.a-i-text58 {
  color: var(--dl-color-primary-100);
  line-height: 1.15;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.a-i-container43 {
  display: flex;
  align-items: flex-end;
  flex-direction: column;
}
.a-i-container44 {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.a-i-container45 {
  display: none;
  align-items: flex-start;
  flex-direction: row;
}
.a-i-icon {
  fill: var(--dl-color-primary-300);
  width: 30px;
  height: 30px;
  margin-right: var(--dl-space-space-unit);
}
.a-i-icon2 {
  fill: var(--dl-color-primary-300);
  width: 30px;
  height: 30px;
  margin-right: var(--dl-space-space-unit);
}
.a-i-icon4 {
  fill: var(--dl-color-primary-300);
  width: 30px;
  height: 30px;
  margin-right: var(--dl-space-space-unit);
}
.a-i-icon6 {
  fill: var(--dl-color-primary-300);
  width: 30px;
  height: 30px;
}
.a-i-container46 {
  display: flex;
  align-items: flex-end;
  margin-left: 0px;
  flex-direction: column;
}
.a-i-container47 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  background-color: var(--dl-color-primary-300);
}
.a-i-container48 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-twounits);
  max-width: var(--dl-size-size-maxi);
  align-self: center;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
}
.a-i-container49 {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
}
.a-i-container50 {
  display: flex;
  align-items: flex-start;
  margin-right: var(--dl-space-space-threeunits);
  flex-direction: column;
}
.a-i-navlink4 {
  color: var(--dl-color-gray-white);
  transition: 0.3s;
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.a-i-navlink4:hover {
  color: var(--dl-color-primary-900);
}
.a-i-navlink5 {
  color: var(--dl-color-gray-white);
  transition: 0.3s;
  text-decoration: none;
}
.a-i-navlink5:hover {
  color: var(--dl-color-primary-900);
}
.a-i-container51 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.a-i-navlink6 {
  color: var(--dl-color-gray-white);
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.a-i-navlink7 {
  color: var(--dl-color-gray-white);
  text-decoration: none;
}
.a-i-container52 {
  display: flex;
  align-items: flex-end;
  flex-direction: column;
}
.a-i-text61 {
  color: var(--dl-color-gray-white);
  margin-bottom: var(--dl-space-space-unit);
}
.a-i-navlink8 {
  color: var(--dl-color-gray-white);
  transition: 0.3s;
  text-decoration: none;
}
.a-i-navlink8:hover {
  color: var(--dl-color-primary-900);
}
