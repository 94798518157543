.full-stack-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.full-stack-container01 {
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-size: cover;
  background-image: url("../playground_assets/wn_06-1500w.jpg");
}
.full-stack-container02 {
  width: 100%;
  display: flex;
  opacity: 90%;
  align-items: center;
  flex-direction: column;
  background-color: var(--dl-color-gray-white);
}
.full-stack-container03 {
  width: 100%;
  height: 80px;
  display: flex;
  max-width: var(--dl-size-size-maxi);
  align-items: center;
  padding-top: 0px;
  padding-left: var(--dl-space-space-twounits);
  padding-right: var(--dl-space-space-twounits);
  padding-bottom: 0px;
  justify-content: space-between;
}
.full-stack-navlink {
  display: contents;
}
.full-stack-image {
  width: 200px;
  object-fit: cover;
  text-decoration: none;
}
.full-stack-container04 {
  display: flex;
  align-items: flex-start;
}
.full-stack-navlink01 {
  color: var(--dl-color-primary-100);
  transition: 0.3s;
  text-decoration: none;
}
.full-stack-navlink01:hover {
  color: var(--dl-color-primary-300);
}
.full-stack-navlink02 {
  color: var(--dl-color-primary-100);
  transition: 0.3s;
  margin-left: var(--dl-space-space-twounits);
  text-decoration: none;
}
.full-stack-navlink02:hover {
  color: var(--dl-color-primary-300);
}
.full-stack-navlink03 {
  color: var(--dl-color-primary-100);
  transition: 0.3s;
  margin-left: var(--dl-space-space-twounits);
  text-decoration: none;
}
.full-stack-navlink03:hover {
  color: var(--dl-color-primary-300);
}
.full-stack-navlink04 {
  color: var(--dl-color-primary-100);
  transition: 0.3s;
  margin-left: var(--dl-space-space-twounits);
  text-decoration: none;
}
.full-stack-navlink04:hover {
  color: var(--dl-color-primary-300);
}
.full-stack-container05 {
  width: 100%;
  height: 750px;
  display: flex;
  padding: var(--dl-space-space-twounits);
  max-width: var(--dl-size-size-maxi);
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.full-stack-container06 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.full-stack-text01 {
  color: var(--dl-color-primary-5001);
  width: 650px;
  font-size: 80px;
  font-style: normal;
  font-family: Urbanist;
  font-weight: 700;
  line-height: 1.15;
  margin-bottom: var(--dl-space-space-twounits);
  text-transform: none;
  text-decoration: none;
}
.full-stack-text03 {
  color: var(--dl-color-gray-white);
  width: 50%;
  height: 100%;
  padding: 0px;
  font-size: 20px;
  font-family: Manrope;
  font-weight: 500;
  text-transform: none;
  text-decoration: none;
}
.full-stack-container07 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  margin-top: var(--dl-space-space-threeunits);
  align-items: flex-start;
  margin-bottom: var(--dl-space-space-threeunits);
  justify-content: center;
}
.full-stack-container08 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  padding: 0px;
  max-width: var(--dl-size-size-maxi);
  flex-direction: row;
  justify-content: space-between;
}
.full-stack-container09 {
  width: 33%;
  display: flex;
  padding: var(--dl-space-space-twounits);
  align-items: center;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: column;
  justify-content: flex-start;
}
.full-stack-image01 {
  height: 180px;
  object-fit: cover;
  margin-bottom: var(--dl-space-space-twounits);
}
.full-stack-text06 {
  color: var(--dl-color-primary-100);
  text-align: center;
  margin-bottom: var(--dl-space-space-twounits);
}
.full-stack-text09 {
  text-align: center;
}
.full-stack-container10 {
  width: 34%;
  display: flex;
  padding: var(--dl-space-space-twounits);
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.full-stack-image02 {
  height: 180px;
  object-fit: cover;
  margin-bottom: var(--dl-space-space-twounits);
}
.full-stack-text12 {
  color: var(--dl-color-primary-100);
  text-align: center;
  margin-bottom: var(--dl-space-space-twounits);
}
.full-stack-text15 {
  color: var(--dl-color-primary-300);
  font-size: 20px;
  font-style: normal;
  text-align: center;
  font-family: Manrope;
  font-weight: 600;
  line-height: 1.15;
  margin-bottom: var(--dl-space-space-twounits);
  text-transform: none;
  text-decoration: none;
}
.full-stack-text18 {
  text-align: center;
}
.full-stack-container11 {
  width: 33%;
  display: flex;
  padding: var(--dl-space-space-twounits);
  align-items: center;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: column;
  justify-content: flex-start;
}
.full-stack-image03 {
  height: 180px;
  object-fit: cover;
  margin-bottom: var(--dl-space-space-twounits);
}
.full-stack-container12 {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
}
.full-stack-text21 {
  color: var(--dl-color-primary-100);
  margin-bottom: var(--dl-space-space-twounits);
}
.full-stack-text24 {
  color: var(--dl-color-gray-black);
  text-align: center;
}
.full-stack-container13 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-threeunits);
  margin-bottom: var(--dl-space-space-threeunits);
  padding-bottom: var(--dl-space-space-threeunits);
  justify-content: center;
  background-color: var(--dl-color-primary-900);
}
.full-stack-container14 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-twounits);
  max-width: var(--dl-size-size-maxi);
  align-items: flex-start;
  flex-direction: column;
  justify-content: space-between;
}
.full-stack-text27 {
  color: var(--dl-color-primary-100);
  font-size: 25px;
  font-style: normal;
  text-align: center;
  font-family: Urbanist;
  font-weight: 500;
  line-height: 1.15;
  margin-bottom: var(--dl-space-space-threeunits);
  text-transform: none;
  text-decoration: none;
}
.full-stack-image04 {
  width: 100%;
  object-fit: cover;
}
.full-stack-container15 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
  margin-bottom: var(--dl-space-space-threeunits);
  justify-content: center;
}
.full-stack-container16 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-twounits);
  max-width: var(--dl-size-size-maxi);
  align-items: flex-start;
  flex-direction: column;
  justify-content: space-between;
}
.full-stack-container17 {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.full-stack-container18 {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.full-stack-text30 {
  color: var(--dl-color-primary-100);
  font-size: 25px;
  font-style: normal;
  text-align: center;
  font-family: Urbanist;
  font-weight: 600;
  line-height: 1.15;
  margin-bottom: var(--dl-space-space-twounits);
  text-transform: none;
  text-decoration: none;
}
.full-stack-image05 {
  height: 150px;
  object-fit: cover;
  margin-bottom: 0px;
}
.full-stack-container19 {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.full-stack-text33 {
  color: var(--dl-color-primary-100);
  font-size: 25px;
  font-style: normal;
  text-align: center;
  font-family: Urbanist;
  font-weight: 600;
  line-height: 1.15;
  margin-bottom: var(--dl-space-space-twounits);
  text-transform: none;
  text-decoration: none;
}
.full-stack-image06 {
  height: 150px;
  object-fit: cover;
  margin-bottom: 0px;
}
.full-stack-container20 {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.full-stack-text36 {
  color: var(--dl-color-primary-100);
  font-size: 25px;
  font-style: normal;
  text-align: center;
  font-family: Urbanist;
  font-weight: 600;
  line-height: 1.15;
  margin-bottom: var(--dl-space-space-twounits);
  text-transform: none;
  text-decoration: none;
}
.full-stack-image07 {
  height: 150px;
  object-fit: cover;
  margin-bottom: 0px;
}
.full-stack-container21 {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.full-stack-text39 {
  color: var(--dl-color-primary-100);
  font-size: 25px;
  font-style: normal;
  text-align: center;
  font-family: Urbanist;
  font-weight: 600;
  line-height: 1.15;
  margin-bottom: var(--dl-space-space-twounits);
  text-transform: none;
  text-decoration: none;
}
.full-stack-image08 {
  height: 150px;
  object-fit: cover;
  margin-bottom: 0px;
}
.full-stack-container22 {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.full-stack-text42 {
  color: var(--dl-color-primary-100);
  font-size: 25px;
  font-style: normal;
  text-align: center;
  font-family: Urbanist;
  font-weight: 600;
  line-height: 1.15;
  margin-bottom: var(--dl-space-space-twounits);
  text-transform: none;
  text-decoration: none;
}
.full-stack-image09 {
  height: 150px;
  object-fit: cover;
  margin-bottom: 0px;
}
.full-stack-container23 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  background-color: var(--dl-color-gray-400);
}
.full-stack-container24 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxi);
  align-items: flex-start;
  padding-top: var(--dl-space-space-threeunits);
  padding-left: var(--dl-space-space-twounits);
  padding-right: var(--dl-space-space-twounits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-threeunits);
  justify-content: space-between;
}
.full-stack-text45 {
  color: var(--dl-color-gray-white);
  font-size: 60px;
  font-style: normal;
  font-family: Urbanist;
  font-weight: 600;
  line-height: 1.15;
  margin-bottom: var(--dl-space-space-threeunits);
  text-transform: none;
  text-decoration: none;
}
.full-stack-container25 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.full-stack-container26 {
  width: 200px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.full-stack-image10 {
  height: 100px;
  object-fit: cover;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.full-stack-text46 {
  color: var(--dl-color-gray-white);
  font-size: 22px;
  font-style: normal;
  text-align: center;
  font-family: Urbanist;
  font-weight: 500;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
.full-stack-container27 {
  width: 200px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.full-stack-image11 {
  height: 100px;
  object-fit: cover;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.full-stack-text49 {
  color: var(--dl-color-gray-white);
  font-size: 22px;
  font-style: normal;
  text-align: center;
  font-family: Urbanist;
  font-weight: 500;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
.full-stack-container28 {
  width: 200px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.full-stack-image12 {
  height: 100px;
  object-fit: cover;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.full-stack-text52 {
  color: var(--dl-color-gray-white);
  font-size: 22px;
  font-style: normal;
  text-align: center;
  font-family: Urbanist;
  font-weight: 500;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
.full-stack-container29 {
  width: 200px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.full-stack-image13 {
  height: 100px;
  object-fit: cover;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.full-stack-text55 {
  color: var(--dl-color-gray-white);
  font-size: 22px;
  font-style: normal;
  text-align: center;
  font-family: Urbanist;
  font-weight: 500;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
.full-stack-container30 {
  width: 200px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.full-stack-image14 {
  height: 100px;
  object-fit: cover;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.full-stack-text58 {
  color: var(--dl-color-gray-white);
  font-size: 25px;
  font-style: normal;
  text-align: center;
  font-family: Urbanist;
  font-weight: 500;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
.full-stack-container31 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  background-color: var(--dl-color-primary-900);
}
.full-stack-container32 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxi);
  align-items: flex-start;
  padding-top: var(--dl-space-space-threeunits);
  padding-left: var(--dl-space-space-twounits);
  padding-right: var(--dl-space-space-twounits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-threeunits);
  justify-content: space-between;
}
.full-stack-text61 {
  color: var(--dl-color-primary-100);
  font-size: 60px;
  font-style: normal;
  font-family: Urbanist;
  font-weight: 600;
  line-height: 1.15;
  margin-bottom: var(--dl-space-space-threeunits);
  text-transform: none;
  text-decoration: none;
}
.full-stack-container33 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.full-stack-container34 {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.full-stack-image15 {
  height: 100px;
  object-fit: cover;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.full-stack-text62 {
  color: var(--dl-color-primary-100);
  font-size: 25px;
  font-style: normal;
  text-align: center;
  font-family: Urbanist;
  font-weight: 600;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
.full-stack-container35 {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.full-stack-image16 {
  height: 100px;
  object-fit: cover;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.full-stack-text65 {
  color: var(--dl-color-primary-100);
  font-size: 25px;
  font-style: normal;
  text-align: center;
  font-family: Urbanist;
  font-weight: 600;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
.full-stack-container36 {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.full-stack-image17 {
  height: 100px;
  object-fit: cover;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.full-stack-text70 {
  color: var(--dl-color-primary-100);
  font-size: 25px;
  font-style: normal;
  text-align: center;
  font-family: Urbanist;
  font-weight: 600;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
.full-stack-container37 {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.full-stack-image18 {
  height: 100px;
  object-fit: cover;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.full-stack-text74 {
  color: var(--dl-color-primary-100);
  font-size: 25px;
  font-style: normal;
  text-align: center;
  font-family: Urbanist;
  font-weight: 600;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
.full-stack-container38 {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.full-stack-image19 {
  height: 100px;
  object-fit: cover;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.full-stack-text78 {
  color: var(--dl-color-primary-100);
  font-size: 25px;
  font-style: normal;
  text-align: center;
  font-family: Urbanist;
  font-weight: 600;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
.full-stack-container39 {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.full-stack-image20 {
  height: 100px;
  object-fit: cover;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.full-stack-text81 {
  color: var(--dl-color-primary-100);
  font-size: 25px;
  font-style: normal;
  text-align: center;
  font-family: Urbanist;
  font-weight: 600;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
.full-stack-footer {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.full-stack-container40 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-twounits);
  max-width: var(--dl-size-size-maxi);
  align-self: center;
  align-items: flex-start;
  flex-direction: row;
}
.full-stack-container41 {
  width: 50%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.full-stack-text84 {
  color: var(--dl-color-primary-100);
  line-height: 1.15;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.full-stack-navlink05 {
  color: var(--dl-color-primary-100);
  transition: 0.3s;
  border-color: var(--dl-color-primary-300);
  padding-left: 0px;
  border-radius: 0px;
  padding-right: 0px;
  padding-bottom: var(--dl-space-space-unit);
  text-decoration: none;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 2px;
}
.full-stack-navlink05:hover {
  color: var(--dl-color-primary-100);
  border-color: var(--dl-color-gray-400);
}
.full-stack-container42 {
  width: 50%;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
}
.full-stack-navlink06 {
  color: var(--dl-color-primary-100);
  line-height: 1.15;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
  text-decoration: none;
}
.full-stack-container43 {
  display: flex;
  align-items: flex-end;
  flex-direction: column;
}
.full-stack-container44 {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.full-stack-container45 {
  display: none;
  align-items: flex-start;
  flex-direction: row;
}
.full-stack-icon {
  fill: var(--dl-color-primary-300);
  width: 30px;
  height: 30px;
  margin-right: var(--dl-space-space-unit);
}
.full-stack-icon2 {
  fill: var(--dl-color-primary-300);
  width: 30px;
  height: 30px;
  margin-right: var(--dl-space-space-unit);
}
.full-stack-icon4 {
  fill: var(--dl-color-primary-300);
  width: 30px;
  height: 30px;
  margin-right: var(--dl-space-space-unit);
}
.full-stack-icon6 {
  fill: var(--dl-color-primary-300);
  width: 30px;
  height: 30px;
}
.full-stack-container46 {
  display: flex;
  align-items: flex-end;
  margin-left: 0px;
  flex-direction: column;
}
.full-stack-container47 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  background-color: var(--dl-color-primary-300);
}
.full-stack-container48 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-twounits);
  max-width: var(--dl-size-size-maxi);
  align-self: center;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
}
.full-stack-container49 {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
}
.full-stack-container50 {
  display: flex;
  align-items: flex-start;
  margin-right: var(--dl-space-space-threeunits);
  flex-direction: column;
}
.full-stack-navlink07 {
  color: var(--dl-color-gray-white);
  transition: 0.3s;
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.full-stack-navlink07:hover {
  color: var(--dl-color-primary-900);
}
.full-stack-navlink08 {
  color: var(--dl-color-gray-white);
  transition: 0.3s;
  text-decoration: none;
}
.full-stack-navlink08:hover {
  color: var(--dl-color-primary-900);
}
.full-stack-container51 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.full-stack-navlink09 {
  color: var(--dl-color-gray-white);
  transition: 0.3s;
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.full-stack-navlink09:hover {
  color: var(--dl-color-primary-900);
}
.full-stack-navlink10 {
  color: var(--dl-color-gray-white);
  transition: 0.3s;
  text-decoration: none;
}
.full-stack-navlink10:hover {
  color: var(--dl-color-primary-900);
}
.full-stack-container52 {
  display: flex;
  align-items: flex-end;
  flex-direction: column;
}
.full-stack-text87 {
  color: var(--dl-color-gray-white);
  margin-bottom: var(--dl-space-space-unit);
}
.full-stack-navlink11 {
  color: var(--dl-color-gray-white);
  transition: 0.3s;
  text-decoration: none;
}
.full-stack-navlink11:hover {
  color: var(--dl-color-primary-900);
}
